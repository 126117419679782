// import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

// import { Swiper, SwiperSlide } from 'swiper/react';
// import offer2_1 from '../Assets/offer2_1.jpg'
// import offer2_2 from '../Assets/offer2_2.jpg'
import './Offer2.css'

// Import Swiper styles
// import 'swiper/css';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';
// import 'swiper/css/scrollbar';
// import 'swiper/css/autoplay';

const Offer2 = ()=> {
  return (
      
    <div className="offer2">
    {/* <Swiper
      // install Swiper modules
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={}
      slidesPerView={2}
      navigation={true}
      pagination={{ clickable: true }}
      autoplay={{delay:3000}}
      loop={true}
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => console.log('slide change')}
    >
      <SwiperSlide className='cards'><img src={offer2_1} alt="" /></SwiperSlide>
      <SwiperSlide className='cards'><img src={offer2_2} alt="" /></SwiperSlide>
      <SwiperSlide className='cards'><img src={offer2_1} alt="" /></SwiperSlide>
      <SwiperSlide className='cards'><img src={offer2_2} alt="" /></SwiperSlide>
      ...
    </Swiper> */}
        <h1>ADS HERE</h1>

    </div>
  );
};

export default Offer2