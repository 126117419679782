import React from 'react'
import './Populardvd.css'
import dvd_data from '../Assets/dvd_data'
import Item from '../Item/Item'


const Populardvd = () => {
  return (
    <div className='populardvd'>
      <h1>BEST SELLING DVD's</h1>
        <hr />
        <div className="popluardvd-item">
            {dvd_data.map((item,i)=>{
                return <Item key={i} id={item.id} name={item.name} image={item.image} new_price={item.new_price} old_price={item.old_price} />
            })}
        </div>
      
    </div>
    
  )
}

export default Populardvd
