import React from 'react'
import './Footer.css'
import footer_log from '../Assets/logo_big.png'
import instragram_icon from '../Assets/instagram_icon.png'
import pintester_icon from '../Assets/pintester_icon.png'
import whatsapp_icon from '../Assets/whatsapp_icon.png'

const Footer = () => {
  return (
    <div className='footer'>
        <div className="footer-logo">
            {/* <img src={footer_log} alt="" /> */}
            <p>Daily<span>pink</span></p>
        </div>
        <div className="footer-link">
            <ul>
                <li>Company</li>
                <li>Products</li>
                <li>About</li>
                <li>Contacts</li>
            </ul>
            </div>
            <div className="footer-social-icon">
                <div className="footer-icon-container">
                    <img src={instragram_icon} alt="" />
                </div>
                <div className="footer-icon-container">
                    <img src={pintester_icon} alt="" />
                </div>
                <div className="footer-icon-container">
                    <img src={whatsapp_icon} alt="" />
                </div>
            </div>
            <div className="footer-copyright">
                <hr />
                <p>Copyright AMR</p>
            </div>
        
      
    </div>
  )
}

export default Footer 
