import b1_img from "./brand_image1.png";
import b2_img from "./brand_image2.png";
import b3_img from "./brand_image3.png";
import b4_img from "./brand_image4.png";
import b5_img from "./brand_image5.png";
import b6_img from "./brand_image6.png";
import b7_img from "./brand_image7.png";
import b8_img from "./brand_image8.png";


let brand_data = [
    {
        brand_image:b1_img,
        brand_name:"DOC JOHNSON",
    },
    {
        brand_image:b2_img,
        brand_name:"CALEXTICS",
    },
    {
        brand_image:b3_img,
        brand_name:"LELO",
    },
    {
        brand_image:b4_img,
        brand_name:"MAGIC WAND",
    },
    {
        brand_image:b5_img,
        brand_name:"SHOTS",
    },
    {
        brand_image:b6_img,
        brand_name:"WEVIBE",
    },
    {
        brand_image:b7_img,
        brand_name:"WOMANNIZER",
    },
    {
        brand_image:b8_img,
        brand_name:"XR",
    },
];

export default brand_data;
