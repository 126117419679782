import React, { useState } from 'react'
import './Navbar.css'
import cart_icon from '../Assets/cart_icon.png'
import { Link } from 'react-router-dom'
import search_icon from '../Assets/search-b.png'

const Navbar = () => {

  const[menu,setmenu] = useState("Shop");

  return (
    <div className='navbar'>
        <div className="nav-logo">
     
          <p>Daily<span>pink</span></p>
        </div>

      <div className="search-bar">
        <input type="text" placeholder='Search' />
        <img src={search_icon} alt="" />
      </div>
       

      <div className="clicks">
        <ul className="nav-menu">
        <li onClick={()=> setmenu("Shop")}><Link style={{textDecoration:"none", color:'white'}} to="/">Shop</Link>{menu==="Shop"?<hr/>:<></>}</li>
        <li onClick={()=> setmenu("Men")}><Link style={{textDecoration:'none',color:'white'}} to="/mens">Men</Link>{menu==="Men"?<hr/>:<></>}</li>
        <li onClick={()=> setmenu("Women")}><Link style={{textDecoration:'none',color:'white'}} to="/womens">Women</Link>{menu==="Women"?<hr/>:<></>}</li>
        <li onClick={()=> setmenu("DVD's")}><Link style={{textDecoration:'none',color:'white'}} to="/dvds">DVD's</Link>{menu==="DVD's"?<hr/>:<></>}</li>
       </ul>

       <div className="nav-login-cart">
        <Link to="/login"><button>Login</button></Link> 
        
        <Link to="/cart"><img src={cart_icon} alt="" /></Link>
        <div className="nav-cart-count">0</div>
       </div>
        </div>

       

    </div>
  )
}

export default Navbar
