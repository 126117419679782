import React from 'react'
import Hero from '../Components/Hero/Hero'
import Popular from '../Components/Popular/Popular'
import Offers from '../Components/Offers/Offers'
import NewCollection from '../Components/NewCollections/NewCollection'
import Offer2 from '../Components/Offer2/Offer2'
import Brands from '../Components/Brands/Brands'
import NewsLetter from '../Components/NewsLetter/NewsLetter'
import Populardvd from '../Components/Populardvd/Populardvd'


const Shop = () => {
  return (
    <div>
    <Hero/>
    <Popular/>
    <Populardvd/>
    <Offers/>
    <NewCollection/>
    <Offer2/>
    <Brands/>
    <NewsLetter/>
    
    </div>
  )
}

export default Shop
