import bg_sample from './sample_bg.jpg'


let dvd_data = [
    {
        id:1.1,
    name:"Striped Flutter Sleeve Overlap Collar Peplum Hem Blouse",
    image:bg_sample,
    new_price:50.00,
    old_price:80.50,
    },
    {
        id:2.1,
    name:"Striped Flutter Sleeve Overlap Collar Peplum Hem Blouse",
    image:bg_sample,
    new_price:50.00,
    old_price:80.50,
    },
    {
        id:3.1,
    name:"Striped Flutter Sleeve Overlap Collar Peplum Hem Blouse",
    image:bg_sample,
    new_price:50.00,
    old_price:80.50,
    },
    {
        id:4.1,
    name:"Striped Flutter Sleeve Overlap Collar Peplum Hem Blouse",
    image:bg_sample,
    new_price:50.00,
    old_price:80.50,
    },
];

export default dvd_data;