import React from 'react'
import './Brands.css'
import brand_data from '../Assets/brands_data'

const Brands = () => {
  return (
    <div>
      <div className="brands">
      <h1>SHOP BY BRANDS</h1>
        <hr/>
        <div className="brand_cards">
            {brand_data.map((item,i)=>{
                return <Brandscard key={i}  brand_data={item.brand_image} brand_name={item.brand_name}/>
            })}
            </div>
      </div>
    </div>
  )
}

const Brandscard =(props)=>{
  return(
    <div className="brand-card">
    <img src={props.brand_data} alt="" />
    <h3>{props.brand_name}</h3>
    </div>
       )
    
    }


export default Brands
